<template>
  <button
    v-on="listeners"
    v-bind="attrs"
    type="button"
    :class="[custom, sizeClasses, modeClasses, isLoading]"
    class="cbutton"
  >
    <span class="flex items-center">
      <base-icon
        class="hidden md:block mr-1"
        :name="icon"
        size="sm"
        v-if="forceIcon"
      />
      <base-icon
        class="block md:hidden"
        :name="icon"
      />
      <span class="hidden md:block">{{ text }}</span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'base-button',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: 'button'
    },
    mode: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'md'
    },
    icon: {
      type: String,
      default: 'none'
    },
    forceIcon: {
      type: Boolean,
      default: false
    },
    custom: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners
      }
    },
    attrs: function () {
      return {
        ...this.$attrs
      }
    },
    isLoading: function () {
      return { 'loader-in-element': this.loading }
    },
    sizeClasses: function () {
      return {
        'w-12 md:w-auto h-12 md:h-8 md:px-4': this.size === 'xs',
        'w-12 md:w-auto h-12 md:h-10 md:px-6': this.size === 'md'
      }
    },
    modeClasses: function () {
      return {
        'cbutton--primary': this.mode === 'primary',
        'cbutton--secondary': this.mode === 'secondary',
        'cbutton--prim-phantom': this.mode === 'primary-phantom',
        'cbutton--sec-phantom': this.mode === 'secondary-phantom',
        'cbutton--tools': this.mode === 'tools',
        'cbutton--core': this.mode === 'core'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cbutton {
  @apply flex items-center justify-center space-x-1 text-sm font-semibold rounded-full md:rounded focus:outline-none transition-colors duration-300;
  &.cbutton--primary {
    @apply text-white bg-primary hover:bg-primary-500 active:bg-primary-800 focus:bg-primary-800;
  }
  &.cbutton--secondary {
    @apply text-white bg-secondary hover:bg-secondary-500 active:bg-secondary-800;
  }
  &.cbutton--prim-line {
    @apply bg-white text-primary hover:bg-primary-50 border-2 border-solid border-primary active:bg-primary active:text-white focus:bg-blue-50 focus:text-primary focus:outline-none;
  }
  &.cbutton--prim-phantom {
    @apply bg-primary-50 text-primary hover:text-primary-500 hover:bg-primary-100 focus:bg-primary-100 focus:text-primary;
  }
  &.cbutton--sec-phantom {
    @apply bg-secondary-50 text-secondary hover:text-secondary-500 focus:bg-secondary-100 focus:text-secondary;
  }
  &.cbutton--tools {
    @apply text-white hover:bg-secondary-900 active:bg-secondary active:text-white focus:bg-secondary-900 focus:outline-none;
  }
  &.cbutton--core {
    @apply bg-white text-secondary hover:bg-primary-50 hover:text-primary active:bg-primary-100 active:text-primary focus:outline-none;
  }
  &:active {
    transform: scale(0.96);
  }
}
</style>
