<template>
    <button
        v-on="listeners"
        v-bind="attrs"
        :class="[custom, sizeClasses, modeClasses, isLoading]"
        class="cbutton">
        <span v-if="text" class="flex items-center">
            <base-icon :name="icon" size="sm" class="mr-1" v-if="hasIcon"/>
            <span>{{ text }}</span>
        </span>
        <slot v-else />
    </button>
</template>

<script>
import icons from '@/components/icons/_requireIcons'
export default {
  name: 'base-button',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary', 'soft'].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      default: 'none',
      validator (value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['xs', 'sm', 'md'].indexOf(value) !== -1
      }
    },
    custom: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners
      }
    },
    isLoading: function () {
      return { 'loader-in-element': this.loading }
    },
    attrs: function () {
      return {
        ...this.$attrs
      }
    },
    hasIcon: function () {
      return this.icon !== 'none'
    },
    modeClasses: function () {
      return {
        'cbutton--primary': this.mode === 'primary',
        'cbutton--secondary': this.mode === 'secondary',
        'cbutton--soft': this.mode === 'soft'
      }
    },
    sizeClasses: function () {
      return {
        'h-8 px-4': this.size === 'xs',
        'h-9 px-4': this.size === 'sm',
        'h-10 px-4 md:px-6': this.size === 'md'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .cbutton {
    @apply flex items-center justify-center space-x-1 text-sm font-semibold rounded-lg focus:outline-none transition-colors duration-300 disabled:opacity-70 disabled:cursor-not-allowed;
    &.cbutton--primary {
      @apply text-white bg-primary hover:bg-primary-500 active:bg-primary-800 focus:bg-primary-800;
      &.loader-in-element:before {
        border: 2px solid var(--primary-50) ;
        border-top-color: var(--primary-900);
      }
    }
    &.cbutton--secondary {
      @apply text-white bg-secondary hover:bg-secondary-500 active:bg-secondary-800;
    }
    &.cbutton--soft {
      @apply bg-primary-50 text-primary hover:text-primary-500 hover:bg-primary-100 focus:bg-primary-100 focus:text-primary;
    }
    &:active {
      transform: scale(0.96);
    }
  }
</style>
