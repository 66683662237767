import { uuidv4 } from '@/utils/general'

const state = () => ({
  loading: false,
  feedback: [],
  isOpenFilterPanel: false,
  csvOptions: {
    counter: null,
    ref: null,
    links: []
  }
})

const getters = {
  loading: state => state.loading,
  feedback: state => state.feedback,
  isOpenFilterPanel: state => state.isOpenFilterPanel,
  csvCounter: state => state.csvOptions.counter,
  csvOptions: state => state.csvOptions
}

const mutations = {
  incrementCsvCounter (state) {
    state.csvOptions.counter++
  },
  setCsvRef (state, value) {
    state.csvOptions.ref = value
  },
  setCsvLinks (state, value) {
    state.csvOptions.links = value
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  addFeedback (state, feedback) {
    state.feedback = [...state.feedback, feedback]
  },
  removeFeedback (state, id) {
    state.feedback = state.feedback.filter(item => item.id !== id)
  },
  setIsOpenFilterPanel (state, value) {
    state.isOpenFilterPanel = value
  }
}

const actions = {
  showFeedback ({ commit }, options) {
    options.id = uuidv4()
    commit('addFeedback', options)

    const duration = options?.duration || process.env.VUE_APP_DEFAULT_FEEDBACK_DURATION || 3000
    setTimeout(() => {
      commit('removeFeedback', options.id)
    }, duration)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
