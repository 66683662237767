import router from '@/router'
import { login } from '@/services/auth/login'
import { me } from '@/services/auth/me'
import { i18n, getLocale, getLocales } from '@/i18n'
import { updateUser } from '@/services/auth/updateUser'
import moment from 'moment'

const state = () => ({
  user: null,
  locale: getLocale(),
  auth: {
    loggedIn: false,
    accessToken: null,
    refreshToken: null
  }
})

const getters = {
  user: state => state.user,
  loggedIn: state => state.user && state.auth.loggedIn && state.auth.accessToken && state.auth.refreshToken,
  accessToken: state => state.auth.accessToken,
  refreshToken: state => state.auth.refreshToken,
  locale: state => state.locale,
  color: state => state.user?.color,
  logo: state => state.user?.urlLogo,
  groupIds: state => state.user?.agrupaciones.map(item => item.id)
}

const mutations = {
  setUser (state, user) {
    state.user = user
    state.auth.loggedIn = true

    if (user.idioma) {
      const locales = getLocales().map(locale => locale.toLowerCase())
      const locale = locales.includes(user.idioma.toLowerCase()) ? user.idioma.toLowerCase() : i18n.fallbackLocale
      i18n.locale = locale
      state.locale = locale
      moment.locale(locale)
      document.querySelector('html').setAttribute('lang', locale)
    }
  },
  setColor (state, color) {
    if (state.user) {
      state.user.color = color
    }
  },
  setLocale (state, locale) {
    i18n.locale = locale
    state.user.idioma = locale
    state.locale = locale
    moment.locale(locale)
    document.querySelector('html').setAttribute('lang', locale)
  },
  loggedOut (state) {
    state.auth.loggedIn = false
  },
  removeUser (state) {
    state.user = null
    state.auth.loggedIn = false
    state.locale = getLocale()
    i18n.locale = getLocale()
    moment.locale(getLocale())
    document.querySelector('html').setAttribute('lang', state.locale)
  },
  setTokens (state, { accessToken, refreshToken }) {
    state.auth.accessToken = accessToken
    state.auth.refreshToken = refreshToken
  },
  removeTokens (state) {
    state.auth.accessToken = null
    state.auth.refreshToken = null
  }
}

const actions = {
  async login ({ commit, dispatch }, { email, password }) {
    const data = await login(email, password)
    commit('setTokens', {
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    })

    await dispatch('downloadMe')

    router.push({ name: process.env.VUE_APP_REDIRECT_LOGGED_IN })
  },
  async downloadMe ({ commit, dispatch, getters }) {
    const user = await me()
    const groupIds = user?.agrupaciones.map(item => item.id)

    const groups = user.agrupaciones
    const groupId = groups[0].id
    commit('filters/setGroups', groups, { root: true })
    commit('filters/setGroupId', groupId, { root: true })

    await dispatch('filters/downloadCategoriesSites', groupIds, { root: true })

    commit('setUser', user)
  },
  updateUser ({ getters }) {
    const data = { ...getters.user }
    updateUser(data.id, data)
  },
  async logout ({ commit }) {
    commit('loggedOut')
    await router.push({ name: process.env.VUE_APP_REDIRECT_FAILED_AUTHENTICATION })
    commit('filters/removeFilters', null, { root: true })
    commit('removeUser')
    commit('removeTokens')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
