<template>
  <div>
    <button
      v-on="listeners"
      v-bind="attrs"
      type="button"
      :class="[sizeClasses, modeClasses, custom, isLoading]"
      class="cbutton"
    >
      <base-icon
        :name="name"
        :size="iconSize"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'base-button-icon',
  props: {
    name: {
      type: String,
      require: true
    },
    mode: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: String,
      default: 'md'
    },
    size: {
      type: String,
      default: 'lg'
    },
    custom: {
      type: [String, Object],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners
      }
    },
    attrs: function () {
      return {
        ...this.$attrs
      }
    },
    isLoading: function () {
      return { 'loader-in-element': this.loading }
    },
    sizeClasses: function () {
      return {
        'w-8 h-8': this.size === 'xs',
        'w-10/5 h-10/5': this.size === 'md',
        'h-11 w-11': this.size === 'lg',
        'h-12 w-12': this.size === 'xl'
      }
    },
    modeClasses: function () {
      return {
        'cbutton--primary': this.mode === 'primary',
        'cbutton--secondary': this.mode === 'secondary',
        'cbutton--soft': this.mode === 'soft'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cbutton {
  @apply group flex items-center justify-center rounded-full cursor-pointer transition-colors duration-300 focus:outline-none focus:ring-0;
  &.cbutton--primary {
    @apply text-white bg-primary hover:bg-primary-500 active:bg-primary-800 focus:bg-primary-800;
  }
  &.cbutton--secondary {
    @apply text-white bg-secondary hover:bg-secondary-500 active:bg-secondary-800;
  }
  &.cbutton--soft {
    @apply bg-primary-50 text-primary hover:text-primary-500 hover:bg-primary-100 focus:bg-primary-100 focus:text-primary;
  }
}
button {
  &:active {
    transform: scale(0.9);
  }
}
</style>
