<template>
  <!-- El width (w-64) es fundamental para que crezca sin empujar el bloque situado a su derecha -->
  <div
    class="flex flex-grow items-center overflow-hidden w-64"
  >
    <template v-if="hasTags">
      <div class="flex-none mr-2">
        <base-button @click="removeAllTags" size="xs" custom="rounded-full">
          {{ $t('app.clearFilters') }}
        </base-button>
      </div>
      <div class="filters-applied flex flex-row space-x-2 overflow-y-auto w-full">
        <base-filter-tag-item
          v-for="(tag, index) in tags"
          :key="index"
          :tag="tag"
          @removeTag="removeTag(tag)"
        />
      </div>
    </template>
    <template v-else>
      <div class="flex h-8 items-center mx-auto">
        <base-icon name="filter" size="sm" class="text-secondary-200" />
        <span class="text-sm text-secondary-200 font-medium">{{ $t('app.noFilters') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'base-table-filter-tags',
  props: {
    tags: {
      type: [Array],
      required: true
    }
  },
  computed: {
    hasTags: function () {
      return this.tags.length > 0
    }
  },
  methods: {
    removeTag: function (val) {
      this.$emit('removeTag', val)
    },
    removeAllTags: function () {
      this.$emit('removeAllTags')
    }
  }
}
</script>

<style lang="scss" scoped></style>
