import { getCurrencyFormat, getDecimalFormat, getPercentFormat } from '@/i18n/numbers'

const feedbackMixin = {
  methods: {
    doFeedback: function (message = null, type = 'error', icon = 'bell', duration = 3000) {
      if (!message) {
        message = this.$t('feedback.error.general')
      }
      const options = {
        message,
        type,
        icon,
        duration
      }

      this.$store.dispatch('app/showFeedback', options)
    }
  }
}

const currencyFilter = {
  filters: {
    money: function (value, countryCode = 'es', locale = null, format = null) {
      return getCurrencyFormat(value, countryCode, locale, format)
    },
    number: function (value, minDecimal = 0, maxDecimal = 2, locale = null, format = null) {
      return getDecimalFormat(value, minDecimal, maxDecimal, locale, format)
    },
    percent: function (value, minDecimal = 0, maxDecimal = 2, locale = null, format = null) {
      return getPercentFormat(value, minDecimal, maxDecimal, locale, format)
    }
  }
}

const chartsMixin = {
  methods: {
    anyChartResult: function (charts) {
      return Array.isArray(charts) && charts.length && charts.map(chart => chart.series && chart.series.length).every(value => value)
    }
  }
}

export { feedbackMixin, currencyFilter, chartsMixin }
