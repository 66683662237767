<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <div
      v-if="isOpen"
      :class="[customClass, setPosition]"
      class="text-secondary absolute mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
    >
      <slot />
      <util-keys @keyup.esc="escDropdown" />
    </div>
  </transition>
</template>

<script>
import UtilKeys from '@/components/utilities/UtilKeys'
export default {
  name: 'dropwdown-base',
  components: {
    UtilKeys
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: 'w-56'
    },
    position: {
      type: String,
      default: 'top-left'
    }
  },
  computed: {
    setPosition: function () {
      return {
        'origin-bottom-right right-0': this.position === 'bottom-right',
        'origin-top-left left-0': this.position === 'top-left',
        'origin-top-right right-0': this.position === 'top-right',
        'origin-center centered': this.position === 'center'
      }
    }
  },
  methods: {
    escDropdown: function () {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.centered {
  @apply left-1/2 transform -translate-x-1/2;
}
</style>
