import axiosInstance from '@/services/axiosInstance'

async function updateUser (id, data) {
  if (data.id) {
    delete data.id
  }
  if (data.password) {
    delete data.password
  }
  if (data.agrupaciones) {
    data.agrupacion = data.agrupaciones
    delete data.agrupaciones
  }

  const req = {
    url: 'clientes/' + id,
    method: 'PUT',
    data
  }

  const res = await axiosInstance(req)

  return res.data
}

export { updateUser }
