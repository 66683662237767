import Vue from 'vue'
import Vuex from 'vuex'
import { i18n } from '@/i18n'
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import auth from './modules/auth'
import filters from './modules/filters'
import moment from 'moment'

Vue.use(Vuex)

const authState = createPersistedState({
  paths: ['auth', 'filters'],
  rehydrated: rehydrated
})

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    filters
  },
  plugins: [authState]
})

export default store

function rehydrated ({ getters }) {
  i18n.locale = getters['auth/locale']
  moment.locale(getters['auth/locale'])
  document.querySelector('html').setAttribute('lang', i18n.locale)
}
