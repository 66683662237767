<template>
  <div class="messages absolute bottom-14 left-0 w-full z-20">
    <transition-group name="feedback">
      <div v-for="feedback of data" :key="feedback.id" :class="getToastColorsClass(feedback.type)" class="flex shadow-lg items-center w-full md:w-1/2 mx-auto rounded-full text-sm mt-2">
        <span class="w-9 h-9 rounded-full flex items-center justify-center bg-black bg-opacity-20">
          <BaseIcon :name="feedback.icon" size="sm" />
        </span>
        <span class="flex-grow ml-2 font-medium">{{ feedback.message }}</span>
        <span class="w-9 h-9 rounded-full flex items-center justify-center cursor-pointer opacity-50 hover:opacity-100" @click="close(feedback.id)">
          <BaseIcon name="cross" size="xs" />
        </span>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'top-notification',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getToastColorsClass: function (type) {
      return {
        'bg-danger text-danger-50': type === 'error',
        'bg-secondary text-secondary-50': type === 'warning',
        'bg-success text-success-50': type === 'success',
        'white-090 text-secondary-700': type === 'info'
      }
    },
    close: function (id) {
      this.$store.commit('app/removeFeedback', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback-enter-active,
.feedback-leave-active,
.feedback-move {
  transition: 250ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}
.feedback-enter {
  opacity: 0;
  transform: scaleY(0.5);
}
.feedback-enter-to {
  opacity: 1;
  transform: scaleY(1);
}
.feedback-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>
