<template>
  <div id="app" class="w-full h-full">
    <AppLayout>
      <router-view/>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'

export default {
  name: 'App',
  components: { AppLayout }
}
</script>

<style lang="scss">
.tight-apexchart .apexcharts-tooltip {
  white-space: pre-wrap !important;
  max-width: 200px;
}

.tight-apexchart .apexcharts-tooltip-y-group {
    display: flex;
}

.tight-apexchart .apexcharts-tooltip-text-label {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apexcharts-tooltip-y-group {
    display: flex;
}

.apexcharts-tooltip-text-label {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden-apexcharts-tooltip-text-label .apexcharts-tooltip-text-label {
  display: none;
}
</style>
