let handleClickOutside
export const Outside = {
  bind: function (el, binding, vnode) {
    handleClickOutside = (e) => {
      e.stopPropagation()
      const {
        handler,
        exclude
      } = binding.value
      let clickedOnExcludedEl = false
      // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
      if (exclude) {
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            let excludedDomEl = null
            if (excludedEl) {
              // If it's a vue component grab the element, otherwise it is the element
              excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl
              clickedOnExcludedEl = excludedDomEl.contains(e.target)
            }
          }
        })
      }
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        handler(e)
      }
    }
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
  },
  unbind: function () {
    document.removeEventListener('click', handleClickOutside)
    document.removeEventListener('touchstart', handleClickOutside)
  }
}
