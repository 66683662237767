<template>
  <component
    :is="iconComponent"
    role="img"
    :class="[iconSize, custom]"
    class="inline-block fill-current"
  />
</template>

<script>
import icons from '@/components/icons/_requireIcons'
export default {
  name: 'base-icon',
  props: {
    name: {
      type: String,
      required: true,
      validator: function (value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    },
    size: {
      type: String,
      default: 'md'
    },
    custom: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconComponent: function () {
      return icons[this.name]
    },
    iconSize: function () {
      return {
        'w-3 h-3': this.size === 'tiny',
        'w-4 h-4': this.size === 'xs',
        'w-5 h-5': this.size === 'sm',
        'w-6 h-6': this.size === 'md',
        'w-8 h-8': this.size === 'lg'
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
