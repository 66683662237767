<template>
  <div v-if="loading" class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50 z-50">
    <div class="text-center">
      <div class="w-9 h-9 mx-auto rounded-full shadow-lg global-loader bg-white"></div>
      <span class="block text-xs mt-2 py-1 px-3 font-medium text-secondary-500 bg-white rounded-full shadow">{{ $t('app.loading') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
