import axiosInstance from '@/services/axiosInstance'

async function me () {
  const req = {
    url: 'me',
    method: 'GET'
  }

  const res = await axiosInstance(req)

  return res.data
}

export { me }
