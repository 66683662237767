import axiosInstance from '@/services/axiosInstance'

async function getCategoriesSites (groupIds) {
  const req = {
    url: 'datos/categoriasSitios',
    method: 'POST',
    data: groupIds
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getCategoriesSites }
