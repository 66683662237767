<template>
  <component :is="layout">
    <slot></slot>
    <app-notification :data="feedback"></app-notification>
    <app-loader :loading="loading"></app-loader>
  </component>
</template>

<script>
import AppNotification from '@/components/core/AppNotification'
import AppLoader from '@/components/core/AppLoader'

const defaultLayout = process.env.VUE_APP_DEFAULT_LAYOUT || 'DefaultLayout'

export default {
  name: 'AppLayout',
  components: {
    AppLoader,
    AppNotification
  },
  computed: {
    layout: function () {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import('@/layouts/' + layout + '.vue')
    },
    feedback: function () {
      return this.$store.getters['app/feedback']
    },
    loading: function () {
      return this.$store.getters['app/loading']
    }
  }
}
</script>
