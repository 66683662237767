import { i18n } from '@/i18n'

function getPrimaryMenu () {
  return [
    {
      name: i18n.t('app.dashboard'),
      path: 'dashboard'
    },
    {
      name: i18n.t('app.visualRank'),
      path: 'visual-rank'
    },
    {
      name: i18n.t('app.brandShare'),
      path: 'brandshare'
    },
    {
      name: i18n.t('app.pricingAnalysis'),
      path: 'pricing-analysis'
    },
    {
      name: i18n.t('app.analyzedMarket'),
      path: 'analyzed-market'
    },
    {
      name: i18n.t('app.features'),
      path: 'features'
    }
  ]
}

function getOrderMenu () {
  return [
    {
      name: i18n.t('app.brand'),
      prop: 'brandName',
      order: 0
    },
    {
      name: i18n.t('app.position'),
      prop: 'positionAVG',
      order: 0
    },
    {
      name: i18n.t('app.price'),
      prop: 'pvp',
      order: 0
    },
    {
      name: i18n.t('app.visualRank'),
      prop: 'visualRank',
      order: -1
    },
    {
      name: i18n.t('app.approved'),
      prop: 'approved',
      order: 0
    }
  ]
}

export { getPrimaryMenu, getOrderMenu }
