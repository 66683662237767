import moment from 'moment'
import { getOrderMenu } from '@/utils/menu'
import { getCategoriesSites } from '@/services/general/getCategoriesSites'
import { getCategoriesSitesByGroupId } from '@/services/general/getCategoriesSitesByGroupId'
import { i18n } from '@/i18n'

function getLabelFilter (filter, locale) {
  if (filter.i18n && filter.i18n[locale]) {
    return filter.i18n[locale]
  }

  return filter.name
}

function defaultDimensions () {
  return {
    data: {
      filter1: {
        name: '',
        i18n: {},
        values: null
      },
      filter2: {
        name: '',
        i18n: {},
        values: null
      },
      filter3: {
        name: '',
        i18n: {},
        values: null
      },
      filter4: {
        name: '',
        i18n: {},
        values: null
      },
      filter5: {
        name: '',
        i18n: {},
        values: null
      }
    },
    selected: {
      filter1: [],
      filter2: [],
      filter3: [],
      filter4: [],
      filter5: []
    }
  }
}

const defaultView = 'table'
const state = () => ({
  changeCounter: 0,
  changeFiltersCounter: 0,
  general: {
    categoriesSites: [],
    maxPrice: null,
    dateRange: {
      start: null,
      end: null
    }
  },
  dimensions: defaultDimensions(),
  resultsCounter: 0,
  healthIndexOK: 1,
  healthIndexKO: 1,
  healthIndexFilter: {
    selected: [],
    values: [
      { label: i18n.t('app.approveds'), value: true, name: 'healthIndex' },
      { label: i18n.t('app.noApproveds'), value: false, name: 'healthIndex' }
    ]
  },
  groupId: null,
  countryCode: null,
  categoryId: null,
  siteId: null,
  startDate: null,
  endDate: null,
  minPrice: 0,
  maxPrice: 100,
  view: defaultView,
  hiddenView: false,
  order: getOrderMenu(),
  groups: [],
  filters: {}
})

const getters = {
  changeCounter: state => state.changeCounter,
  changeFiltersCounter: state => state.changeFiltersCounter,
  general: state => state.general,
  resultsCounter: state => state.resultsCounter,
  healthIndexOK: state => state.healthIndexOK,
  healthIndexKO: state => state.healthIndexKO,
  healthIndex: state => {
    const value = (state.healthIndexOK * 100) / (state.healthIndexOK + state.healthIndexKO)
    return (Math.round(value * 100) / 100)
  },
  healthIndexFilter: state => state.healthIndexFilter,
  view: state => state.view,
  hiddenView: state => state.hiddenView,
  order: state => state.order,
  activeOrder: state => {
    return state.order.find(item => !!item.order)
  },
  groupId: state => state.groupId,
  countryCode: state => state.countryCode,
  categoryId: state => state.categoryId,
  siteId: state => state.siteId,
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  minPrice: state => state.minPrice,
  maxPrice: state => state.maxPrice,
  groups: state => state.groups,
  category: state => locale => {
    const categorySite = state.general.categoriesSites.find(categorySite => categorySite.idCategoria === state.categoryId)
    const key = 'categoria_' + locale
    if (categorySite && categorySite[key]) {
      return categorySite[key]
    }
    return categorySite?.categoria ? categorySite.categoria : ''
  },
  categories: (state) => (locale) => {
    const categories = new Set()
    for (const element of state.general.categoriesSites) {
      if (state.countryCode.toLowerCase() === element.iso.toLowerCase()) {
        const key = 'categoria_' + locale
        if (element[key]) {
          categories.add(element[key])
        } else {
          categories.add(element.categoria)
        }
      }
    }

    return Array.from(categories).sort().map(category => {
      const categorySite = state.general.categoriesSites.find(categorySite => {
        const key = 'categoria_' + locale
        return (categorySite.categoria === category || categorySite[key] === category) && categorySite.idSitio === state.siteId
      })

      const disabled = !categorySite
      return {
        name: category,
        categoryId: categorySite ? categorySite.idCategoria : null,
        siteId: categorySite ? categorySite.idSitio : null,
        disabled
      }
    })
  },
  dashboardCategories: state => {
    const categories = new Set()
    for (const element of state.general.categoriesSites) {
      categories.add(element.categoria)
    }

    return Array.from(categories).sort().map(category => {
      return {
        name: category,
        disabled: false
      }
    })
  },
  countries: state => {
    const countries = {}
    state.general.categoriesSites.forEach(categorySite => {
      if (!Object.prototype.hasOwnProperty.call(countries, categorySite.iso)) {
        countries[categorySite.iso] = {
          code: categorySite.iso,
          name: categorySite.pais,
          currency: categorySite.currency
        }
      }
    })

    return Object.values(countries)
  },
  site: state => {
    const categorySite = state.general.categoriesSites.find(categorySite => categorySite.idSitio === state.siteId)
    return categorySite?.sitio ? categorySite.sitio : ''
  },
  sites: state => {
    const sites = new Set()
    state.general.categoriesSites.forEach(element => {
      if (state.countryCode.toLowerCase() === element.iso.toLowerCase()) {
        sites.add(element.sitio)
      }
    })

    return Array.from(sites).sort().map(site => {
      const categorySite = state.general.categoriesSites.find(categorySite => {
        return categorySite.sitio === site && categorySite.idCategoria === state.categoryId
      })

      const disabled = !categorySite
      return {
        name: site,
        categoryId: categorySite ? categorySite.idCategoria : null,
        siteId: categorySite ? categorySite.idSitio : null,
        disabled
      }
    })
  },
  dimensions: state => state.dimensions.data,
  selectedDimensions: state => state.dimensions.selected,
  country: (state, getters) => {
    const code = state.countryCode
    const country = getters.countries.find(c => c.code === code)
    return country
  },
  currency: (state, getters) => {
    const country = getters.country
    return country ? country.currency : '-'
  },
  names: (state, getters) => (locale) => {
    const dimensions = getters.dimensions

    return Object.keys(dimensions).map(key => {
      const label = getLabelFilter(dimensions[key], locale)
      return {
        key,
        name: label
      }
    }).filter(item => item.name)
  },
  csvFieldOptions: (state, getters) => (locale) => {
    const names = getters.names(locale)
    return names.map(item => {
      return {
        label: item.name,
        value: item.key.replace('filter', 'dimension')
      }
    })
  }
}

const mutations = {
  incrementChangeCounter (state) {
    state.changeCounter++
  },
  incrementChangeFiltersCounter (state) {
    state.changeFiltersCounter++
  },
  setGeneral (state, general) {
    state.general = general
  },
  setGeneralMaxPrice (state, maxPrice) {
    state.general.maxPrice = maxPrice
  },
  setGeneralStartDate (state, value) {
    state.general.dateRange.start = value
  },
  setGeneralEndDate (state, value) {
    state.general.dateRange.end = value
  },
  setResultsCounter (state, counter) {
    state.resultsCounter = counter
  },
  setHealthIndexOK (state, healthIndexOK) {
    state.healthIndexOK = healthIndexOK
  },
  setHealthIndexKO (state, healthIndexKO) {
    state.healthIndexKO = healthIndexKO
  },
  setHealthIndexFilterSelected (state, value) {
    state.healthIndexFilter.selected = value
  },
  setView (state, view) {
    state.view = view
  },
  setHiddenView (state, value) {
    state.hiddenView = value
  },
  setLocaleOrder (state) {
    state.order = getOrderMenu()
  },
  setOrder (state, { prop, value }) {
    state.order = [...state.order.map(item => {
      if (item.prop === prop) {
        item.order = value
      } else {
        item.order = 0
      }
      return item
    })]
  },
  setGroupId (state, groupId) {
    state.groupId = groupId
  },
  setCountryCode (state, countryCode) {
    state.countryCode = countryCode
  },
  setCategoryId (state, categoryId) {
    state.categoryId = categoryId
  },
  setSiteId (state, siteId) {
    state.siteId = siteId
  },
  resetCategoryIdAndSiteId (state) {
    const categorySite = state.general.categoriesSites.find(element => {
      return state.countryCode.toLowerCase() === element.iso.toLowerCase()
    })

    // update price and dates
    const maxPrice = categorySite.pvpMax
    const startDate = moment(categorySite.fechaInicio, 'DD-MM-YYYY')
    const endDate = moment(categorySite.fechaFin, 'DD-MM-YYYY')

    state.minPrice = 0
    state.maxPrice = maxPrice
    state.general.maxPrice = maxPrice

    state.general.dateRange.start = startDate.format('YYYY-MM-DD')
    state.general.dateRange.end = endDate.format('YYYY-MM-DD')

    const end = endDate.clone()
    state.startDate = startDate < end.subtract(7, 'days') ? end.format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD')
    state.endDate = endDate.format('YYYY-MM-DD')
    // end

    state.groupId = categorySite.idGroup
    state.categoryId = categorySite.idCategoria
    state.siteId = categorySite.idSitio
  },
  setStartDate (state, date) {
    state.startDate = date
  },
  setEndDate (state, date) {
    state.endDate = date
  },
  setMinPrice (state, price) {
    state.minPrice = price
  },
  setMaxPrice (state, price) {
    state.maxPrice = price
  },
  setGroups (state, groups) {
    state.groups = groups
  },
  removeFilters (state) {
    state.view = defaultView
    state.groupId = null
    state.categoryId = null
    state.siteId = null
    state.startDate = null
    state.endDate = null
    state.minPrice = 0
    state.maxPrice = 100
    state.groups = []
    state.order = getOrderMenu()
    state.dimensions = defaultDimensions()
    state.healthIndexFilter.selected = []
  },
  setCategory (state, { category, locale }) {
    const iso = state.countryCode.toLowerCase()
    const key = 'categoria_' + locale
    const categorySite = state.general.categoriesSites.find(categorySite => {
      return (categorySite.categoria === category || categorySite[key] === category) &&
      (categorySite.iso.toLowerCase() === iso) &&
      (categorySite.idSitio === state.siteId)
    })

    if (categorySite) {
      const maxPrice = categorySite.pvpMax
      const startDate = moment(categorySite.fechaInicio, 'DD-MM-YYYY')
      const endDate = moment(categorySite.fechaFin, 'DD-MM-YYYY')

      if (state.minPrice === 0 && state.maxPrice === state.general.maxPrice) {
        state.maxPrice = maxPrice
      }

      state.general.maxPrice = maxPrice
      state.general.dateRange.start = startDate.format('YYYY-MM-DD')
      state.general.dateRange.end = endDate.format('YYYY-MM-DD')

      state.groupId = categorySite.idGroup
      state.categoryId = categorySite.idCategoria
    } else {
      const categorySite = state.general.categoriesSites.find(categorySite => {
        return (categorySite.categoria === category || categorySite[key] === category) &&
        (categorySite.iso.toLowerCase() === iso)
      })

      state.groupId = categorySite.idGroup
      state.categoryId = categorySite.idCategoria
    }
  },
  setDashboardCategory (state, category) {
    const categorySite = state.general.categoriesSites.find(categorySite => categorySite.categoria === category)
    state.groupId = categorySite.idGroup
    state.categoryId = categorySite.idCategoria
    state.siteId = categorySite.idSitio
  },
  setSite (state, site) {
    const iso = state.countryCode.toLowerCase()
    const categorySite = state.general.categoriesSites.find(categorySite => {
      return (categorySite.sitio === site) &&
      (categorySite.iso.toLowerCase() === iso) &&
      (categorySite.idCategoria === state.categoryId)
    })

    if (categorySite) {
      // update price and dates
      const maxPrice = categorySite.pvpMax
      const startDate = moment(categorySite.fechaInicio, 'DD-MM-YYYY')
      const endDate = moment(categorySite.fechaFin, 'DD-MM-YYYY')

      if (state.minPrice === 0 && state.maxPrice === state.general.maxPrice) {
        state.maxPrice = maxPrice
      }

      state.general.maxPrice = maxPrice
      state.general.dateRange.start = startDate.format('YYYY-MM-DD')
      state.general.dateRange.end = endDate.format('YYYY-MM-DD')

      state.groupId = categorySite.idGroup
      state.siteId = categorySite.idSitio
    } else {
      const categorySite = state.general.categoriesSites.find(categorySite => {
        return (categorySite.sitio === site) &&
        (categorySite.iso.toLowerCase() === iso)
      })

      state.siteId = categorySite.idSitio
    }
  },
  setFilters (state, filters) {
    const obj = {}
    for (const key in filters) {
      const filter = filters[key]
      if (filter) {
        obj[key] = {
          name: filter.nombre,
          values: filter.filtroDatos,
          i18n: {
            es: filter.nombre_es,
            en: filter.nombre_en
          }
        }
      }
    }
    state.dimensions.data = Object.assign({}, obj)
  },
  setSelectedFilter1 (state, values) {
    state.dimensions.selected.filter1 = values
  },
  setSelectedFilter2 (state, values) {
    state.dimensions.selected.filter2 = values
  },
  setSelectedFilter3 (state, values) {
    state.dimensions.selected.filter3 = values
  },
  setSelectedFilter4 (state, values) {
    state.dimensions.selected.filter4 = values
  },
  setSelectedFilter5 (state, values) {
    state.dimensions.selected.filter5 = values
  },
  resetSelectedFilters: function (state) {
    state.dimensions.selected.filter1 = []
    state.dimensions.selected.filter2 = []
    state.dimensions.selected.filter3 = []
    state.dimensions.selected.filter4 = []
    state.dimensions.selected.filter5 = []
    state.healthIndexFilter.selected = []
  }
}

const actions = {
  async downloadCategoriesSites ({ commit }, groupIds) {
    const response = await getCategoriesSites(groupIds)
    const categoriesSites = response.listaCategoriasSitios
    const maxPVP = response.maxPVP
    moment(this.appointment?.date).format('YYYY-MM-DD')

    const general = {
      categoriesSites: categoriesSites,
      maxPrice: maxPVP,
      dateRange: {
        start: null,
        end: null
      }
    }
    commit('setGeneral', general)
    commit('filters/setMinPrice', 0, { root: true })
    commit('filters/setMaxPrice', maxPVP, { root: true })

    if (categoriesSites.length) {
      const groupId = categoriesSites[0].idGroup
      const categoryId = categoriesSites[0].idCategoria
      const countryCode = categoriesSites[0].iso
      const siteId = categoriesSites[0].idSitio
      const maxPrice = categoriesSites[0].pvpMax
      const startDate = moment(categoriesSites[0].fechaInicio, 'DD-MM-YYYY')
      const endDate = moment(categoriesSites[0].fechaFin, 'DD-MM-YYYY')

      commit('setGroupId', groupId)
      commit('setCategoryId', categoryId)
      commit('setCountryCode', countryCode)
      commit('setSiteId', siteId)
      commit('setGeneralMaxPrice', maxPrice)
      commit('filters/setMaxPrice', maxPrice, { root: true })
      commit('filters/setGeneralEndDate', endDate.format('YYYY-MM-DD'), { root: true })
      commit('filters/setGeneralStartDate', startDate.format('YYYY-MM-DD'), { root: true })
      commit('filters/setEndDate', endDate.format('YYYY-MM-DD'), { root: true })
      if (startDate < endDate.subtract(7, 'days')) {
        commit('filters/setStartDate', endDate.subtract(7, 'days').format('YYYY-MM-DD'), { root: true })
      } else {
        commit('filters/setStartDate', startDate.format('YYYY-MM-DD'), { root: true })
      }
    }
  },
  async downloadCategoriesSitesByGroupId ({ commit, getters }) {
    const groupId = getters.groupId
    const response = await getCategoriesSitesByGroupId(groupId)
    const categoriesSites = response.listaCategoriasSitios
    const maxPVP = response.maxPVP
    const startDate = moment(response.fechaInicio, 'DD-MM-YYYY')
    const endDate = moment(response.fechaFin, 'DD-MM-YYYY')

    moment(this.appointment?.date).format('YYYY-MM-DD')

    const general = {
      categoriesSites: categoriesSites,
      maxPrice: maxPVP,
      dateRange: {
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD')
      }
    }
    commit('setGeneral', general)
    commit('filters/setMinPrice', 0, { root: true })
    commit('filters/setMaxPrice', maxPVP, { root: true })
    commit('filters/setEndDate', endDate.format('YYYY-MM-DD'), { root: true })
    if (startDate < endDate.subtract(7, 'days')) {
      commit('filters/setStartDate', endDate.subtract(7, 'days').format('YYYY-MM-DD'), { root: true })
    } else {
      commit('filters/setStartDate', startDate.format('YYYY-MM-DD'), { root: true })
    }

    if (categoriesSites.length) {
      const groupId = categoriesSites[0].idGroup
      const categoryId = categoriesSites[0].idCategoria
      const countryCode = categoriesSites[0].iso
      const siteId = categoriesSites[0].idSitio
      commit('setGroupId', groupId)
      commit('setCategoryId', categoryId)
      commit('setCountryCode', countryCode)
      commit('setSiteId', siteId)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
