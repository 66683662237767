<template>
  <div class="text-center leading-tight">
    <img
      src="@/assets/img/not-found.svg"
      :class="customImgClass"
      class="mx-auto"
    />
    <h3 class="text-xl font-bold mt-2">No se han encontrado resultados</h3>
    <span class="text-secondary-500 font-medium">Compruebe los criterios de búsqueda</span>
  </div>
</template>

<script>
export default {
  name: 'not-found-message',
  props: {
    customImgClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
