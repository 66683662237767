import axios from 'axios'
import router from '@/router'
import store from '@/store'

let isRefreshing = false
let pendingRequests = []
const headers = {
  'Content-Type': 'application/json'
}

const auth = (config) => {
  const accessToken = store.getters['auth/accessToken']
  if (accessToken) {
    const aux = config.headers ? { ...config.headers } : {}
    config.headers = Object.assign({
      Authorization: 'Bearer ' + store.getters['auth/accessToken']
    }, aux)
  }

  return config
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  timeout: process.env.VUE_APP_API_TIMEOUT,
  headers
})

const refreshTokenOrFail = (error) => {
  const { config, response: { status } } = error
  const originalRequest = config

  if (status === 401) {
    if (isRefreshing) {
      return new Promise((resolve) => {
        return addPendingRequest((token) => {
          originalRequest.headers.Authorization = 'Bearer ' + token
          return resolve(axios.request(originalRequest))
        })
      })
    }
    return retry(originalRequest)
  }

  return Promise.reject(error)
}

const retry = (originalRequest) => {
  isRefreshing = true

  const uri = process.env.VUE_APP_URL_API_BASE + '/refresh'
  const data = {
    refresh_token: store.getters['auth/refreshToken']
  }

  return axios.post(uri, data).then((response) => {
    if (response.status === 200) {
      const accessToken = response.data.access_token
      const refreshToken = response.data.refresh_token

      store.commit('auth/setTokens', { accessToken, refreshToken })

      retryPendingWithNewToken(accessToken)

      originalRequest.headers.Authorization = 'Bearer ' + accessToken
      isRefreshing = false
      pendingRequests = []

      return axios.request(originalRequest)
    }
  }).catch(() => {
    isRefreshing = false
    clearTokensAndReturnToLogin()
  })
}

const addPendingRequest = (callback) => {
  pendingRequests.push(callback)
}

const retryPendingWithNewToken = (accessToken) => {
  pendingRequests = pendingRequests.filter(callback => callback(accessToken))
}

const clearTokensAndReturnToLogin = () => {
  store.commit('auth/removeUser')
  store.commit('auth/removeTokens')

  router.push({ name: process.env.VUE_APP_REDIRECT_FAILED_AUTHENTICATION })
}

axiosInstance.interceptors.request.use(auth, error => Promise.reject(error))
axiosInstance.interceptors.response.use(response => response, refreshTokenOrFail)

export default axiosInstance
