import countriesRaw from '@/i18n/countries.json'
import { i18n } from '@/i18n'

const countries = Object.keys(countriesRaw).map(key => countriesRaw[key])

function getGeneralFormat (value, locale = null, options = {}, format = null) {
  if (!locale) {
    locale = i18n.locale
  }

  const nf = new Intl.NumberFormat(locale, options)

  if (format === 'parts') {
    return nf.formatToParts(value)
  }

  return nf.format(value)
}

function getDecimalFormat (value, minDecimal = 0, maxDecimal = 2, locale = null, format = null) {
  const options = {
    style: 'decimal',
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal
  }

  return getGeneralFormat(value, locale, options, format)
}

function getPercentFormat (value, minDecimal = 0, maxDecimal = 2, locale = null, format = null) {
  const options = {
    style: 'percent',
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal
  }

  return getGeneralFormat(value / 100, locale, options, format)
}

function getCurrencyFormat (value, countryCode = 'es', locale = null, format = null) {
  const country = countries.find(item => {
    return item.countryCode.toLowerCase() === countryCode.toLowerCase() || item.currencyCode.toLowerCase() === countryCode.toLowerCase()
  })
  const code = country ? country.currencyCode : 'EUR'

  const options = {
    style: 'currency',
    currency: code
  }

  return getGeneralFormat(value, locale, options, format)
}

function getCurrencySymbolPosition (value, countryCode = 'es', locale = null) {
  const currency = getCurrencyFormat(value, countryCode, locale, 'parts')

  const symbolPosition = currency.findIndex(item => item.type === 'currency')
  const numberPosition = currency.findIndex(item => item.type === 'integer')

  return (symbolPosition > numberPosition) ? 'right' : 'left'
}

export { getDecimalFormat, getPercentFormat, getCurrencyFormat, getCurrencySymbolPosition }
