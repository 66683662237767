<template>
  <div
    v-on="listeners"
    :class="[activeClass, disableClassess]"
    class="fake-select select-none h-8 flex items-center pl-3 pr-2 bg-white shadow transition-shadow duration-150 rounded-lg text-sm font-medium"
  >
    <div v-if="isMultiple"
        class="flex overflow-hidden"
      >
      <span class="truncate">{{ data[0] }}</span> <span class="px-1 text-secondary-300">&rtrif;</span> <span class="truncate opacity-80 lg:opacity-100">{{ data[1] }}</span>
    </div>
    <div v-else>
        <span v-if="icon">
          <span class="hidden lg:block">{{ label }}</span>
          <span class="lg:hidden"><base-icon :name="icon" size="sm" /></span>
        </span>
        <span class="whitespace-nowrap" v-else>{{ label }}</span>
    </div>
    <base-icon
      name="chevrondown"
      :class="toogle"
      class="ml-2 transform transition-transform duration-150"
    />
  </div>
</template>

<script>
export default {
  name: 'base-header-filter-select',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMultiple: function () {
      return this.data.length > 0
    },
    listeners: function () {
      return {
        ...this.$listeners
      }
    },
    toogle: function () {
      return {
        'rotate-180': this.isOpen
      }
    },
    activeClass: function () {
      return {
        active: this.isOpen
      }
    },
    disableClassess: function () {
      return this.isDisabled ? 'opacity-50 text-secondary pointer-events-none' : 'hover:shadow-md hover:text-primary cursor-pointer'
    }
  }
}
</script>

<style lang="scss" scoped>
.fake-select {
    @apply border-2 border-solid border-transparent;
  &.active {
    @apply border-primary text-primary;
  }
  &:is(.active) {
    box-shadow: none!important;
  }
}
</style>
