<template>
  <div>
    <select
      v-model="localValue"
      :class="[setSize, custom]"
      class="form-select"
    >
      <option v-for="option in data" :value="option[fieldValue]" :key="option[fieldKey]">{{
        option[fieldText]
      }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'base-select',
  inheritAttrs: false,
  props: {
    value: {
      required: true
    },
    data: {
      type: [Object, Array],
      required: true
    },
    size: {
      type: String,
      default: 'none'
    },
    custom: {
      type: String,
      default: ''
    },
    fieldValue: {
      type: String,
      default: 'value'
    },
    fieldText: {
      type: String,
      default: 'name'
    },
    fieldKey: {
      type: String,
      default: 'value'
    }
  },
  data: function () {
    return {
      localValue: this.value
    }
  },
  computed: {
    selectedValue: function () {
      return this.value.filter(x => x.selected)[0].name
    },
    setSize: function () {
      return {
        'h-8 py-1 text-sm': this.size === 'xs',
        'h-10': this.size === 'md'
      }
    }
  },
  watch: {
    value: function (newValue) {
      this.localValue = newValue
    },
    localValue: function (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
