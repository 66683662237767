import Vue from 'vue'
import VueI18n from 'vue-i18n'
import numberFormats from '@/i18n/numberFormats'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getLocale () {
  return window.navigator.language || window.navigator.userLanguage || process.env.VUE_APP_I18N_LOCALE || 'en'
}

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  numberFormats
})

function getLocales () {
  return Object.keys(i18n.messages)
}

export { i18n, getLocale, getLocales }
