<template>

  <span
    @click="toggleHighlight"
    v-on="listeners"
    :class="[highlightClassess, custom]"
    class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer"
  >
    <base-icon :name="icon" />
  </span>
</template>

<script>
import icons from '@/components/icons/_requireIcons'
export default {
  name: 'base-toogle-button',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary', 'soft'].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      default: 'none',
      validator (value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['xs', 'sm', 'md'].indexOf(value) !== -1
      }
    },
    custom: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    inverse: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      highlight: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.highlight = this.value
    })
  },
  computed: {
    listeners: function () {
      return {
        ...this.$listeners
      }
    },
    isLoading: function () {
      return { 'loader-in-element': this.loading }
    },
    hasIcon: function () {
      return this.icon !== 'none'
    },
    highlightClassess: function () {
      return this.highlight === !this.inverse ? 'bg-primary-50 hover:bg-primary-100 text-primary' : 'bg-secondary-50 text-secondary-500 hover:text-secondary-800 hover:bg-secondary-100'
    },
    sizeClasses: function () {
      return {
        'w-8 h-8': this.size === 'xs',
        'w-10/5 h-10/5': this.size === 'md',
        'h-11 w-11': this.size === 'lg',
        'h-12 w-12': this.size === 'xl'
      }
    }
  },
  methods: {
    toggleHighlight: function () {
      this.highlight = !this.highlight
      this.$emit('status', this.highlight)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
