<template>
  <div />
</template>
<script>
export default {
  name: 'util-keys',
  mounted: function () {
    this.handler = event => {
      this.$emit('keyup', event)
    }
    window.addEventListener('keyup', this.handler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keyup', this.handler)
  }
}
</script>
<style lang="css" scoped>
    div {
        display: none;
    }
</style>
