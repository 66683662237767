<template>
  <div
    id="specificChart"
    class="donut-size"
  >
    <div class="pie-wrapper">
      <span class="label">
        <span class="num">{{ hIndex }}</span><span class="smaller">%</span>
      </span>
      <div
        :style="setPieStyle"
        class="pie">
        <div
            :style="`transform:rotate(${deg}deg)`"
            class="left-side half-circle"></div>
        <div
            :style="setRightSide"
            class="right-side half-circle"></div>
      </div>
      <div class="shadow"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-health-index',
  props: {
    hIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    deg: function () {
      if (!this.hIndex) {
        return 0
      }

      let perc = Math.round(this.hIndex)
      if (perc > 100) {
        perc = 100
      } else if (perc < 0) {
        perc = 0
      }

      return Math.round(360 * (perc / 100))
    },
    setPieStyle: function () {
      return this.hIndex > 50 ? 'clip:rect(auto, auto, auto, auto);' : 'clip:rect(0, 1em, 1em, 0.5em);'
    },
    setRightSide: function () {
      return this.hIndex > 50 ? 'transform:rotate(180deg);' : 'transform:rotate(0deg);'
    }
  }
}
</script>

<style lang="scss" scoped>
#percent {
  display: block;
  width: 160px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 50px auto 20px;
  padding: 10px;
  color: #1abc9c;
  font-family: "Lato", Tahoma, Geneva, sans-serif;
  font-size: 35px;
  text-align: center;
}
#donut {
  display: block;
  margin: 0px auto;
  color: #1abc9c;
  font-size: 20px;
  text-align: center;
}
p {
  max-width: 600px;
  margin: 12px auto;
  font-weight: normal;
  font-family: sans-serif;
}
code {
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 0px 4px;
}

// The relavent stuff is all below
$pie-main: var(--success-700);
$pie-text: white;
$pie-shadow: var(--danger-700);
.donut-size {
  font-size: 2rem;
}
.pie-wrapper {
  position: relative;
  width: 1em;
  height: 1em;
  margin: 0px auto;
  .pie {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
  }
  .half-circle {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 0.15em solid $pie-main;
    border-radius: 50%;
    clip: rect(0em, 0.5em, 1em, 0em);
  }
  .right-side {
    transform: rotate(0deg);
  }
  .label {
    position: absolute;
    top: 0.1em;
    right: 0.4em;
    bottom: 0.4em;
    left: 0.4em;
    display: none; //block para visibilizarla
    background: none;
    border-radius: 50%;
    color: $pie-text;
    font-size: 0.3em;
    line-height: 2.6em;
    text-align: center;
    cursor: default;
    z-index: 2;
  }
  .smaller {
    padding-bottom: 20px;
    color: white;
    font-size: 0.75em;
    vertical-align: super;
  }
  .shadow {
    width: 100%;
    height: 100%;
    border: 0.15em solid $pie-shadow;
    border-radius: 50%;
  }
}
</style>
