<template>
  <div
    class="table-wrapper overflow-x-auto w-full">
    <table v-if="!loading">
      <thead>
        <tr>
          <th></th>
          <th class="brand-max-width">{{ $t('app.brand') }}</th>
          <th>{{ $t('app.model') }}</th>
          <th></th>
          <th></th>
          <th class="position">{{ $t('app.position') }}</th>
          <th class="price">{{ $t('app.price') }}</th>
          <th class="ivr-max-width">{{ $t('app.ivr') }}</th>
        </tr>
      </thead>
      <tbody>
        <base-table-row v-for="row in data.productos" :row="row" :key="row.idPrice" />
      </tbody>
    </table>
    <template v-else>
        <span v-for="i in 20" :key="i" class="h-10 rounded-lg border-2 border-white border-solid mb-1 skeleton bg-secondary-100 w-full block"></span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'base-table',
  props: {
    data: {
      type: [Object],
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border: none;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: -4px; /* correct offset on first border spacing if desired */
}

thead {
  tr th {
    @apply text-secondary-500;
    padding: 4px 8px;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
  }
}

// .brand-max-width {
//     width: 120px;
// }
// .ivr-max-width {
//     width: 80px;
// }
// .check {
//   width: 40px;
// }
// .position {
//   max-width: 60px;
// }
// .price {
//   max-width: 60px;
// }

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

</style>
