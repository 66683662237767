import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/i18n'
import { feedbackMixin, currencyFilter, chartsMixin } from '@/mixins/appMixin'
import VueApexCharts from 'vue-apexcharts'
import { Outside } from '@/components/directives/Outside.js'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import VueCompositionAPI from '@vue/composition-api'
import '@/components/base/_baseComponents'
import '@/assets/css/tailwind.css'
import '@/assets/css/colors.css'
import '@vueform/slider/themes/default.css'
import VueInputAutowidth from 'vue-input-autowidth'
import VTooltip from 'v-tooltip'
import HighchartsVue from 'highcharts-vue'
import hc from 'highcharts/highcharts'
import boost from 'highcharts/modules/boost'
import treemap from 'highcharts/modules/treemap'
import exporting from 'highcharts/modules/exporting'
import offlineExporting from 'highcharts/modules/offline-exporting'

Vue.use(HighchartsVue)
treemap(hc)
exporting(hc)
offlineExporting(hc)
boost(hc)

hc.setOptions({
  boost: {
    seriesThreshold: 65
  }
})

Vue.config.productionTip = false

// ApexCharts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.mixin(feedbackMixin)
Vue.mixin(currencyFilter)
Vue.mixin(chartsMixin)
Vue.directive('outside', Outside)
Vue.directive('autowidth', VueInputAutowidth)
Vue.use(vueNumeralFilterInstaller, { locale: 'es-es' })
Vue.use(VueCompositionAPI)
Vue.use(VTooltip)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
