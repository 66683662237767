<template>
  <label class="checkbox-hover">
    <input
        type="checkbox"
        v-bind="$attrs"
        v-on="listeners"
        class="check-custom"
    >
    <span class="check-toggle flex-none"></span>
  </label>
</template>

<script>
export default {
  name: 'base-input-check',
  inheritAttrs: false,
  computed: {
    listeners: function () {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('input', event.target.value)
        }
      }
    },
    attrs: function () {
      return {
        ...this.$attrs
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
  @apply border-2 border-solid border-primary;
}

/* Styles for the focus state appearance of the custom checkbox */
input[type="checkbox"].check-custom:focus ~ .check-toggle {
  border-color: #b0d5ff;
  box-shadow: 0 0 0 2px rgba(23, 133, 255, 0.25);
}

input[type="checkbox"].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input[type="checkbox"].check-custom ~ .check-toggle {
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  background:white;
  top: -2px;
  @apply border-2 border-solid border-primary;
  // &:not(.squared) {
  //   border-radius: 50%;
  // }
}

/* Styles for the hover state appearance of the custom checkbox */
input[type="checkbox"].check-custom:hover ~ .check-toggle {
   @apply border-2 border-solid border-primary-500;
}

/* Styles for the checked state appearance of the custom checkbox */
input[type="checkbox"].check-custom:checked ~ .check-toggle {
  @apply bg-primary border-2 border-solid border-primary;
  background: var(--primary-700)
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
    center no-repeat;
  background-size: 80%;
}

input[type="checkbox"].check-custom:checked ~ .check-label {
    @apply font-bold;
}
</style>
