import axiosInstance from '@/services/axiosInstance'

async function login (email, password) {
  const data = new FormData()
  data.append('username', email)
  data.append('password', password)
  data.append('grant_type', 'password')

  const req = {
    url: process.env.VUE_APP_URL_API_BASE + '/oauth/token?grant_type=password',
    method: 'POST',
    data,
    headers: {
      Authorization: 'Basic ' + process.env.VUE_APP_BASIC_AUTHORIZATION_API
    }
  }

  const res = await axiosInstance(req)
  return res.data
}

export { login }
