<template>
  <div
    :class="customBlock">
    <header :class="customHeader" class="flex items-center">
        <slot name="headerRight" />
        <div class="flex-shrink-0 ml-auto" v-if="hasContent">
            <slot name="headerLeft" />
        </div>
        <div class="flex-shrink-0 ml-auto" v-else>
            <base-button-icon @click="toggle" :class="{'rotate-180':active}" class="transform transition-transform duration-150" mode="soft" :name="customToggleIcon" />
        </div>
    </header>
    <transition
      name="collapsible"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <section v-if="active"
        :style="hasMaxHeight"
        :class="{'overflow-y-auto': overfFlow}">
        <div :class="customContent">
            <slot name="content" />
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import BaseButtonIcon from './BaseButtonIcon.vue'
export default {
  components: { BaseButtonIcon },
  name: 'base-collapsible',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    customBlock: {
      type: String,
      default: ''
    },
    customHeader: {
      type: [String, Object],
      default: ''
    },
    customContent: {
      type: String,
      default: ''
    },
    customToggleIcon: {
      type: String,
      default: 'chevrondown'
    },
    heightLimit: {
      type: Boolean,
      default: true
    },
    overfFlow: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      active: this.open
    }
  },
  computed: {
    hasContent: function () {
      return this.$scopedSlots.headerLeft
    },
    hasMaxHeight: function () {
      return this.heightLimit ? 'max-height:250px;' : 'height:100%'
    }
  },
  methods: {
    toggle () {
      this.active = !this.active
      this.$emit('collapseStatus', this.id, this.active)
    },
    startTransition (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition (el) {
      el.style.height = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-enter-active,
.collapsible-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.collapsible-enter,
.collapsible-leave-to {
  height: 0 !important;
}
.collapsible-max {
  // max-height: 250px;
  overflow-y: auto;
}
</style>
