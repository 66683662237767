import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    meta: { type: 'public' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/legal-notice',
    name: 'legal',
    meta: { type: 'public' },
    component: () => import(/* webpackChunkName: "login" */ '../views/LegalNotice.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: { type: 'public' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Privacy.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    meta: { type: 'cookies' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Cookies.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { type: 'public' },
    component: () => import(/* webpackChunkName: "recoveryPassword" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    meta: { type: 'public' },
    component: () => import(/* webpackChunkName: "recoveryPassword" */ '../views/RecoveryPassword.vue')
  },
  {
    path: '/home',
    name: 'dashboard',
    meta: {
      type: 'private',
      layout: 'TopsLayout',
      menu: ['sites', 'categories', 'views', 'csv']
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/visualrank',
    name: 'visual-rank',
    meta: {
      type: 'private',
      layout: 'PrimaryLayout',
      menu: ['sites', 'categories', 'price', 'date', 'views', 'order', 'filters', 'csv']
    },
    component: () => import(/* webpackChunkName: "visualRank" */ '../views/VisualRank.vue')
  },
  {
    path: '/brandshare',
    name: 'brandshare',
    meta: {
      type: 'private',
      layout: 'PrimaryLayout',
      menu: ['sites', 'categories', 'price', 'date', 'filters', 'csv']
    },
    component: () => import(/* webpackChunkName: "brandshare" */ '../views/BrandShare.vue')
  },
  {
    path: '/pricing-analysis',
    name: 'pricing-analysis',
    meta: {
      type: 'private',
      layout: 'PrimaryLayout',
      menu: ['sites', 'categories', 'price', 'date', 'views', 'order', 'filters', 'csv']
    },
    component: () => import(/* webpackChunkName: "pricingAnalysis" */ '../views/PricingAnalysis.vue')
  },
  {
    path: '/analyzed-market',
    name: 'analyzed-market',
    meta: {
      type: 'private',
      layout: 'PrimaryLayout',
      menu: ['categories', 'price', 'date', 'filters', 'csv']
    },
    component: () => import(/* webpackChunkName: "analyzedMarket" */ '../views/AnalyzedMarket.vue')
  },
  {
    path: '/features',
    name: 'features',
    meta: {
      type: 'private',
      layout: 'PrimaryLayout',
      menu: ['sites', 'categories', 'price', 'date', 'filters', 'csv']
    },
    component: () => import(/* webpackChunkName: "features" */ '../views/Features.vue')
  },
  {
    path: '*',
    name: 'not-found',
    meta: {
      type: 'both'
    },
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach(async (to, from, next) => {
  let type = 'private'
  if (to.meta && to.meta.type) {
    type = to.meta.type
  }

  const loggedIn = store.getters['auth/loggedIn']

  if (type === 'private') {
    if (loggedIn) {
      return next()
    }
    return next({ name: process.env.VUE_APP_REDIRECT_FAILED_AUTHENTICATION })
  }

  if (type === 'public') {
    if (loggedIn) {
      return next({ name: process.env.VUE_APP_REDIRECT_LOGGED_IN })
    }
  }

  return next()
})

export default router
