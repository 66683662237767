<template>
  <span
    class="group flex items-center h-8 rounded-full pl-4 text-sm bg-primary-50 text-primary flex-none"
  >
    <span class="mr-2 group-hover:text-primary-800">{{ tag | label }}</span>
    <base-button-icon
      mode="soft"
      name="cross"
      iconSize="xs"
      size="xs"
      @click="removeTag"
    />
  </span>
</template>

<script>
export default {
  name: 'base-filter-tag-item',
  props: {
    tag: {
      type: [String, Object],
      required: true
    }
  },
  methods: {
    removeTag: function () {
      this.$emit('removeTag', this.tag)
    }
  },
  filters: {
    label: function (value) {
      if (typeof value === 'object') {
        return value.label
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped></style>
