<template>
  <div
    class="w-full h-full flex items-center justify-center"
  >
    <div class="opacity-80 text-center">
      <img
        src="@/assets/img/not-found.svg"
        :class="customImgClass"
        class="mx-auto"
      />
      <p class="text-sm font-medium">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found-message',
  props: {
    message: {
      type: String,
      default: function () { return this.$t('app.noResults') }
    },
    customImgClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
