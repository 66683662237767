import axiosInstance from '@/services/axiosInstance'

async function getCategoriesSitesByGroupId (groupId) {
  const req = {
    url: 'datos/categoriasSitios/' + groupId,
    method: 'GET'
  }

  const res = await axiosInstance(req)

  return res.data
}

export { getCategoriesSitesByGroupId }
