<template>
  <img
    :src="src"
    :alt="alt"
    :title="thetitle"
    :class="custom"
    />
</template>

<script>
export default {
  name: 'logo',
  props: {
    filename: {
      type: String,
      default: 'logo-singular-rank-colour.svg'
    },
    url: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'SingularRank'
    },
    title: {
      type: String,
      default: null
    },
    custom: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      thetitle: this.title ? this.title : this.alt
    }
  },
  computed: {
    src: function () {
      if (this.url) {
        return this.url
      }

      return require(`@/assets/img/${this.filename}`)
    }
  }
}
</script>
