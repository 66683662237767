<template>
  <div class="relative">
    <i
    v-if="showPrepend"
    :class="setIcon"
    class="h-full w-10 absolute z-10 top-0 flex items-center justify-center text-gray-400"><BaseIcon :name="prepend" /></i>
    <input
      autocomplete="off"
      :class="[custom, utilityClasses, sizeClasses]"
      class="form-input"
      v-bind="$attrs"
      v-on="listeners"
    />
    <i
    v-if="showAppend"
    :class="setIcon"
    class="h-full w-10 absolute z-10 top-0 flex items-center justify-center text-gray-400"><BaseIcon :name="append" /></i>
  </div>
</template>

<script>
export default {
  name: 'base-input',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'md'
    },
    prepend: {
      type: String,
      default: 'none'
    },
    append: {
      type: String,
      default: 'none'
    },
    align: {
      type: String,
      default: 'left'
    },
    custom: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizeClasses: function () {
      return {
        'h-8 py-1 text-sm': this.size === 'xs',
        'h-10': this.size === 'md'
      }
    },
    utilityClasses: function () {
      return {
        'pl-9': this.prepend !== 'none',
        'pr-9': this.append !== 'none',
        'text-center': this.align === 'center'
      }
    },
    showAppend: function () {
      return this.append !== 'none'
    },
    showPrepend: function () {
      return this.prepend !== 'none'
    },
    setIcon: function () {
      return {
        'left-0': this.prepend !== 'none',
        'right-0': this.append !== 'none'
      }
    },
    listeners: function () {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('input', event.target.value)
        }
      }
    },
    attrs: function () {
      return {
        ...this.$attrs
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
